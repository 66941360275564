.category {
    display: flex;
    justify-content: center;
    background-color: rgb(255, 218, 218);
    width: 60rem;
    margin: auto;
    justify-content: space-between;
}
.categoryItem {
    background-color: rgb(193, 89, 89);
    border-radius: 50%;
    display: flex;
    padding: 2rem;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.categoryItem .icons, .categoryItem h4 {
    position: absolute;
    color: #fff;
}
.categoryItem .icons{
    transform: translate(0, -90%);
    font-size: 1.4rem;
}
.categoryItem h4 {
    font-size: 0.7rem;
    font-weight: normal;
    transform: translate(0, 20%);
    font-family: 'Kalam', cursive;

}