.detailsWrapper {
    display: flex;
}
h2{
    margin-bottom: 2rem;
    font-size: 1rem;
}
li {
    font-size: 1.2rem;
    line-height: 2.5rem;
}
ul {
    margin-top: 2rem;
}
img{
    height: 15rem;
    border-radius: 1rem;
}
button {
    padding: 1rem 2rem;
    color: #313131;
    background-color: #fff;
    border: 2px solid black;
    margin-right: 2rem;
    font-weight: 600;
}
button.active {
    background-color: red;
    color: #fff;
}
.summary h3{
    font-size: 1rem;
    line-height: 1.4;
    font-weight: normal;
    
}