*{
margin: 0;
padding: 0;
box-sizing: border-box;
}
body {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 15%;
}
code {
  font-family: 'Montserrat', sans-serif;
}
h4 {
  font-size: 1rem;
  color: rgb(56, 56, 56);
}
h3{
  font-size: 1.5rem;
  color: rgb(56, 56, 56);
  line-height: 2.5rem;
  margin: 2rem 0;
}
a{
  color: rgb(56, 56, 56);
}