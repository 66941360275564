.wrapper {
    margin: 4rem 0;
}
.card {
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
    min-height: 15rem ;
    padding: 6rem;
    background-color: rgb(255, 238, 238);
    
}
.card .backdrop {
    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5));
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    z-index: 8 !important;

}
.card img{
    border-radius: 1rem;
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    z-index: 6 !important;
    
}
.card p{
    z-index: 10 !important;
    /* background-color: red; */
    position: absolute;
    left: 50%;
    transform: translate(-50%, 100%);
    color: #fff;
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: 600; 
}