form.search {
    margin: 0 20rem;
    position: relative;
    width: 100%;
    display: flex;
}
form.search .searchInput {
    width: 100%;
    position: relative;
}
form.search .searchInput input {
    border: none;
    background: linear-gradient(35deg, #494949, #313131);
    color: #fff;
    border: none;
    border-radius: 1rem;
    outline: none;
    padding: 1rem 3rem;
    width: 100%;
}
form.search .searchInput svg {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(100%, -50%);
    
}