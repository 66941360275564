.cuisineGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
    grid-gap: 2rem;
}
.cuisineGrid .cuisineItem img{
    height: 10rem;
    width: auto;
    border-radius: 1rem;
}
.cuisineGrid .cuisineItem a{
    text-decoration: none;
}
.cuisineGrid .cuisineItem h4 {
    text-align: center ;
    padding: 1rem;
}